import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  locationList: [],
  customerGrouplist: [],
  locationId: "",
};

const getters = {};

const mutations = {
  SET_LOCATION(state, data) {
    state.locationList = data;
  },
  //Mutate Location
  INSERT_LOCATION(state, data) {
    state.locationList = data;
  },

  SET_CUSTOMER_GROUPS(state, data) {
    state.customerGrouplist = data;
  },
  INSERT_CUSTOMER_GROUP(state, data) {
    state.customerGrouplist = data;
  },
};

const actions = {
  //GET ALL LOCATIONS
  getAllLocations({ commit }) {
    try {
      return new Promise((resolve, reject) => {
        axios
          .get("CRM/getAllLocations", options)
          .then((response) => {
            commit("SET_LOCATIONS");
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },

  //SAVE NEW LOCATIONS
  postLocation({ commit }, payload) {
    //make some kind of ajax request
    try {
      return new Promise((resolve, reject) => {
        axios
          .post("CRM/saveLocation", payload, options)
          .then((response) => {
            commit("INSERT_LOCATION", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },

  updateLocation({ commit }, payload) {
    //make some kind of ajax request
    try {
      return new Promise((resolve, reject) => {
        axios
          .post("CRM/updateLocation", payload, options)
          .then((response) => {
            commit("INSERT_LOCATION", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },

  getAllCustomerGroups({ commit }) {
    try {
      return new Promise((resolve, reject) => {
        axios
          .get("CRM/getAllCustomerGroups", options)
          .then((response) => {
            commit("SET_CUSTOMER_GROUPS");
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },
  //SAVE NEW CUSTOMER GROUP
  postLCustomerGroups({ commit }, payload) {
    //make some kind of ajax request
    try {
      return new Promise((resolve, reject) => {
        axios
          .post('CRM/saveCustomerGroup"', { payload }, options)
          .then((response) => {
            commit("INSERT_CUSTOMER_GROUP", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },
  fetchLocations({ commit }) {
    try {
      return new Promise((resolve, reject) => {
        axios
          .get("/CRM/Locations", options)
          .then((response) => {
            commit("SET_LOCATION", response.data.ResData.DataRet);

            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    } catch (error) {
      commit("INSERT_ERROR", error);
    }
  },

  postCustomerGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CRM/saveCustomerGroup", payload, options)
        .then((response) => {
          //commit("", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
