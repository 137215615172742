import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './routes/routes'
import store from './Store'
import axios from 'axios'
import VueSimpleAlert from "vue-simple-alert"
import VueSweetalert2 from 'vue-sweetalert2';

import vSelect from 'vue-select'
import VueLoading from 'vuejs-loading-plugin'
// Vue.component('v-select', vSelect)


axios.defaults.baseURL = process.env.VUE_APP_API_URL;



Vue.use(VueSweetalert2);
Vue.use(VueRouter)
Vue.use(VueSimpleAlert)

Vue.component('v-select', vSelect)
// Vue.use(VueLoading)
Vue.use(VueLoading, {
  dark: false, // default false
  text: 'Loading Please wait !', // default 'Loading'
  loading: false, // default false
  // customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import 'admin-lte/plugins/datatables/jquery.dataTables'
import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4'
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css'
import 'sweetalert2/dist/sweetalert2.min.css';
// import 'admin-lte/plugins/datatables.net-buttons/js/dataTables.buttons.js'
// import 'admin-lte/plugins/datatables.net-buttons/js/buttons.colVis.min.js'
// import 'admin-lte/plugins/datatables.net-buttons/js/buttons.flash.js'
// import 'admin-lte/plugins/datatables.net-buttons/js/buttons.html5.js'
// import 'admin-lte/plugins/datatables.net-buttons/js/buttons.print.js'

import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js"
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import 'admin-lte/plugins/select2/css/select2.min.css'
import 'admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css'
import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/dist/js/adminlte.min.js'
import 'admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars.min.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  VueSweetalert2,
  render: h => h(App),
}).$mount('#app')
