import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  customer: [],
  statement: [],
};

const getters = {
  customer: (state) => state.customer,
  statement: (state) => state.statement,
};

const mutations = {
  SET_CUSTOMER(state, data) {
    state.customer = data;
  },

  SET_STATEMENT(state, data) {
    state.statement = data;
  },

};

const actions = {
  searchCustomerStatment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/SearchCus/" + payload, options)
        .then((r) => r.data)
        .then((data) => {
        commit("SET_CUSTOMER", data.ResData.searchRes);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  fetchCustomerStatement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/fetchStatement/"+ payload, options)
        .then(({ data }) => {
          commit("SET_STATEMENT", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};

export default { state, getters, mutations, actions };
