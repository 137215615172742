<template>
<div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-md-12">

                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 col-sm-3 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-user"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Customer Count </span>
                            <span class="info-box-number  text-right"> {{customerCount}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-8 col-sm-3 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-file-invoice-dollar"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Receipt Count</span>
                            <span class="info-box-number text-right">{{receiptCount}}</span>
                        </div>
                    </div>
                </div>

                <div class="clearfix hidden-md-up"></div>
                <div class="col-8 col-sm-3 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-dollar-sign"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Gross Sales</span>
                            <span class="info-box-number text-right">Rs.{{grossSales.toFixed(2) }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-8 col-sm-3 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-box"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Sale Qty</span>
                            <span class="info-box-number text-right">{{saleQty.toFixed(2) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                        <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span>
                        <h5 class="description-header">$35,210.43</h5>
                        <span class="description-text">TOTAL REVENUE</span>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                        <span class="description-percentage text-warning"><i class="fas fa-caret-left"></i> 0%</span>
                        <h5 class="description-header">$10,390.90</h5>
                        <span class="description-text">TOTAL COST</span>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                        <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 20%</span>
                        <h5 class="description-header">$24,813.53</h5>
                        <span class="description-text">TOTAL PROFIT</span>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="description-block">
                        <span class="description-percentage text-danger"><i class="fas fa-caret-down"></i> 18%</span>
                        <h5 class="description-header">1200</h5>
                        <span class="description-text">GOAL COMPLETIONS</span>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Sales Analyse </h5>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                                        <i class="fas fa-wrench"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu">
                                        <a href="#" class="dropdown-item">Action</a>
                                        <a href="#" class="dropdown-item">Another action</a>
                                        <a href="#" class="dropdown-item">Something else here</a>
                                        <a class="dropdown-divider"></a>
                                        <a href="#" class="dropdown-item">Separated link</a>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="text-center">
                                        <strong>Top Sales Groups</strong>
                                    </p>
                                    <div class="chart">
                                        <div class="chartjs-size-monitor">
                                            <div class="chartjs-size-monitor-expand">
                                                <div class=""></div>
                                            </div>
                                            <div class="chartjs-size-monitor-shrink">
                                                <div class=""></div>
                                            </div>
                                        </div>

                                        <!-- <canvas id="salesChart" height="180" style="height: 180px; display: block; width: 1061px;" width="1061" class="chartjs-render-monitor"></canvas> -->

                                        <!-- <line-chart v-if="loaded" :width="300" :height="100" :labels="salesDates" :datasets="chartData.datasets" :options="$options.options"></line-chart> -->
                                        <doughnut-chart v-if="loaded" :width="300" :height="120" :labels="groupNames" :datasets="doughData.datasets" :options="doughnutOpt"></doughnut-chart>
                                    </div>

                                </div>

                                <div class="col-md-6">
                                    <p class="text-center">
                                        <strong>Goal Completion</strong>
                                    </p>
                                    <div class="progress-group">
                                        Add Products to Cart
                                        <span class="float-right"><b>160</b>/200</span>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-primary" style="width: 80%"></div>
                                        </div>
                                    </div>

                                    <div class="progress-group">
                                        Complete Purchase
                                        <span class="float-right"><b>310</b>/400</span>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-danger" style="width: 75%"></div>
                                        </div>
                                    </div>

                                    <div class="progress-group">
                                        <span class="progress-text">Visit Premium Page</span>
                                        <span class="float-right"><b>480</b>/800</span>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-success" style="width: 60%"></div>
                                        </div>
                                    </div>

                                    <div class="progress-group">
                                        Send Inquiries
                                        <span class="float-right"><b>250</b>/500</span>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar bg-warning" style="width: 50%"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="card-footer">

                        </div>

                    </div>

                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Year Purchase & Sales Comparison </h5>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                                        <i class="fas fa-wrench"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu">
                                        <a href="#" class="dropdown-item">Action</a>
                                        <a href="#" class="dropdown-item">Another action</a>
                                        <a href="#" class="dropdown-item">Something else here</a>
                                        <a class="dropdown-divider"></a>
                                        <a href="#" class="dropdown-item">Separated link</a>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="text-center">
                                        <strong>Sales Year: {{year}}</strong>
                                    </p>
                                    <div class="chart">
                                        <div class="chartjs-size-monitor">
                                            <div class="chartjs-size-monitor-expand">
                                                <div class=""></div>
                                            </div>
                                            <div class="chartjs-size-monitor-shrink">
                                                <div class=""></div>
                                            </div>
                                        </div>

                                        <!-- <canvas id="salesChart" height="180" style="height: 180px; display: block; width: 1061px;" width="1061" class="chartjs-render-monitor"></canvas> -->
                                        <line-chart v-if="loaded" :width="300" :height="100" :labels="salesDates" :datasets="chartData.datasets" :options="$options.options"></line-chart>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <!-- <div class="card-footer">

                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </section>
</div>
</template>

<script>
import numeral from 'numeral';
import LineChart from './LineChart';
import DoughnutChart from './DougnutChart';
import ChartDataLabels from 'chartjs-plugin-labels'

const options = {
    scales: {
        // xAxes: [{
        //     stacked: false,
        //     categoryPercentage: 0.85,
        //     barPercentage: 0.85,
        //     gridLines: {
        //         display: true
        //     },
        //     fontSize: 6
        // }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                callback: value => numeral(value).format('Rs 0,0')
            }
        }],

    },
    tooltips: {
        mode: 'label',
        callbacks: {
            label(tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                const value = numeral(tooltipItem.yLabel).format('Rs 0,0');

                return `${label}: ${value}`;
            }
        }
    }
};

export default {
    name: "dashBoard",
    options,

    components: {
        LineChart,
        //BarChart,
        DoughnutChart
    },

    data() {
        return {
            customerCount: 0,
            receiptCount: 0,
            grossSales: 0,
            saleQty: 0,
            sales: [],
            salesDates: [],
            purchase: [],
            purchDates: [],
            months: [],
            year: '',
            chartData: null,
            loaded: false,
            doughData: null,
            groupSales: [],
            groupNames: [],
            coloR: [],
            efficiency: [],
            doughnutOpt: null
        }
    },

    methods: {
        fetchCardData() {
            this.$loading(true);
            this.$store.dispatch("dashboard/fetchDashboardCardData", null, {
                root: true
            }).then(() => {
                var data = this.$store.state.dashboard.dashCardData;
                this.customerCount = data[0].customerCount
                this.receiptCount = data[0].receiptCount
                this.grossSales = data[0].grossSales
                this.saleQty = data[0].saleQty
            });
            this.flag = false;

            this.$loading(false);
        },

        fetchMonthRecapData() {
            this.loaded = false;
            this.$store.dispatch("dashboard/fetchDashMonthRecapData", null, {
                root: true
            }).then((data) => {
                data.sales.forEach(e => {
                    this.sales.push(e.Amount)
                    this.salesDates.push(e.Date)
                });
                data.purchase.forEach(e => {
                    this.purchase.push(e.Amount)
                    this.purchDates.push(e.Date)
                });
                data.groupSale.forEach(e => {
                    this.groupNames.push(e.Group)
                    this.groupSales.push(e.Amount)
                });
                this.loaded = true;
            });

            this.chartData = {
                labels: this.salesDates,
                datasets: [

                    {
                        label: 'Sales',
                        backgroundColor: 'rgba(210, 214, 222, 1)',
                        borderColor: 'rgba(210, 214, 222, 1)',
                        fill: true,
                        // pointRadius: false,
                        //    pointColor: 'rgba(210, 214, 222, 1)',
                        // pointStrokeColor: '#c1c7d1',
                        //  pointHighlightFill: '#fff',
                        // pointHighlightStroke: 'rgba(220,220,220,1)',
                        data: this.sales
                    },
                    {
                        label: 'Purchase',
                        backgroundColor: 'rgba(60,141,188,0.9)',
                        borderColor: 'rgba(60,141,188,0.8)',
                        fill: true,
                        pointColor: '#3b8bba',
                        data: this.purchase
                    },

                ]
            }

            this.doughData = {
                labels: this.groupNames,
                datasets: [

                    {
                        label: 'Group Sales Analyse',
                        backgroundColor: ['#00a65a', '#fffff', '#00c0ef', '#d2d6de', '#f49c12', '#01c0ef', '#3c6dbc', '#d2d1de', '#0ff5de', '#f56954'],
                        hoverOffset: 4,
                        fill: true,
                        data: this.groupSales
                    },

                ]
            }

            this.setOptions();
        },

        setOptions() {
            this.doughnutOpt = {
                tooltips: {
                    enabled: true
                },
                pieceLabel: {
                    render: 'label',
                    arc: true,
                    fontColor: '#fff',
                    position: 'outside'
                },
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    labels: {
                        render: 'percentage',
                        fontColor: ['green', 'white', 'red'],
                        precision: 2
                    }
                },

                legend: {
                    position: 'bottom',
                },
                // title: {
                //     display: true,
                //     text: 'Testing',
                //     fontSize: 20
                // },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                // plugins: [ChartDataLabels],
                // plugins: {
                //     labels: {
                //         color: '#ffffff',
                //         formatter: (value) => {
                //             return value + '%'
                //         },
                //           position: 'outside'
                //     }

                //     // labels: {
                //     //     render: 'label',
                //     //     arc: true,
                //     //     fontColor: '#000',
                //     //     position: 'outside'
                //     // }
                // },
            }
        }

    },
    created() {

        this.fetchCardData();
        this.fetchMonthRecapData();
    },

};
</script>

<style>
body {
    background-color: #1a1423;
}

#app {

    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.d-flex {
    display: flex;
}

.info-box-number {
    font-size: 24px !important;

}
</style>
