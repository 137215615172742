<template>
<div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <h1 class="m-0 text-dark">Customer Statement Report</h1>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-12">
                    <!-- Default box -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Filter Data</h3>
                        </div>
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            Search Customer
                                            <v-select class="style-chooser" placeholder="Search Customer" :options="customers" @search="searchCustomer" v-model="customer" label="Name" :value="$store.state.selected"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>

                                    <div class="col-md-5 text-right">
                                        <br />
                                        <button @click="fetchCustomerStatementData" type="button" class="btn-sm btn btn-success mr-1" >Process</button>
                                         <button  type="button " class="btn-sm btn btn-warning mr-1" >Clear</button>
                                        
                                    </div>
                                </div>
                                <div class="row"></div>
                            </form>
                        </div>
                    </div>

                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="table-responsive" >
                                    <table class="table table-hover table-bordered " id="statement">
                                        <thead>
                                            <th colspan="7">Customer : {{ this.customerName }}</th>

                                            <tr>
                                                <th>Transaction Doc No</th>
                                                <th>Invoice Date / Time </th>
                                                <th class="number">Invoice No</th>
                                                <th class="number">Invoice Amount</th>
                                                <th class="number">Earned</th>
                                                <th class="number">Redeemed</th>
                                                <th class="number">Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="c in this.customerStatement" :key="c.id">
                                                  <td>{{ c.OrgDocNo }}</td>
                                                <td>{{ c.BillDate }}</td>
                                                <td class="number">{{ c.BillNo }}</td>
                                                <td class="number">{{ c.Amount.toFixed(2) }}</td>
                                                <td class="number">{{ c.Credit.toFixed(2) }}</td>
                                                <td class="number">{{ c.Debit.toFixed(2) }}</td>
                                                <td class="number">{{ c.Balance.toFixed(2) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import $ from "jquery";

export default {
    data() {
        return {
            reportFilter: null,
            customer: null,
            customers: [],
            customerStatement: [],
            customerName: null,
        };
    },
    computed: {},

    methods: {
        searchCustomer(customer) {
            this.$store
                .dispatch("searchCustomerStatment", customer)
                .then(() => {
                    this.customers = this.$store.state.rptCustomerStatement.customer;
                })
                .catch(() => {});
        },

        fetchCustomerStatementData() {
            var printCounter = 0;
            var custName;
            $("#statement").dataTable().fnDestroy();
            this.$loading(true);
            this.$store
                .dispatch("fetchCustomerStatement", this.customer.Code)
                .then(() => {
                    this.customerStatement =
                        this.$store.state.rptCustomerStatement.statement;
                    custName = this.customerName =
                        this.customerStatement[0].CustCode +
                        "-" +
                        this.customerStatement[0].CustomerName;
                    setTimeout(function () {
                        $("#statement").DataTable({
                            pageLength: 20,
                            processing: true,
                            autoWidth:true,
                            ordering:false,
                            dom: "Bfrtip",

                            buttons: [
                                "copy",
                                {
                                    extend: "excel",
                                    // messageTop: "The information in this table is copyright to Sirius Cybernetics Corp.",
                                },
                                {
                                    extend: "csv",
                                    title: "Statement of " + custName,
                                    messageBottom: null,
                                },
                                {
                                    extend: "pdf",
                                    title: "Statement of " + custName,
                                    messageBottom: null,
                                },
                                {
                                    extend: "print",
                                    title: "Statement of " + custName,
                                    messageBottom: function () {
                                        printCounter++;

                                        if (printCounter === 1) {
                                            return "";
                                        } else {
                                            return (
                                                "You have printed this document " +
                                                printCounter +
                                                " times"
                                            );
                                        }
                                    },
                                    //   messageBottom: null
                                },
                            ],
                        });

                    }, 300);
                    this.$loading(false);
                }).catch((error) => {
                    console.log(error);
                });

        },

    },
    created() {},

    mounted() {},
};
</script>

<style>
.number {
    text-align: right;
    /* value of your choice which suits your alignment */
}
</style>
