<template>
<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
            <a href="https://ninesense.lk"><b>NS Handy</b> CRM <span>v 0.1.2</span></a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Sign in to start your session</p>

                <form @submit.prevent="submit">
                    <div class="input-group mb-3">
                        <!-- <input type="email" class="form-control" placeholder="Email"> -->
                        <input type="text" id="email_address" class="form-control" placeholder="User Name" v-model="userName" required autofocus />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <!-- <input type="password" class="form-control" placeholder="Password"> -->
                        <input type="password" id="password" class="form-control" placeholder="Password" v-model="Password" required />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8"> </div>
                        <div class="col-4">
                            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                            <!-- <button type="submit" class="btn btn-black mr-2">Login</button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import sidenav from "./sidenav.vue";
import {
    mapActions
} from "vuex";

export default {
    data() {
        return {
            userName: "",
            Password: "",
            F_name: "",
            L_Name: "",
        };
    },

    methods: {
        ...mapActions(["login"]),
        submit() {
            //
            console.log("api call start");

            this.$loading(true);

            this.login({
                    user_name: this.userName,
                    password: this.Password
                })
                .then((res) => {

                    console.log(res);
                    this.F_name = res.FirstName;
                    this.F_name = res.LastName;
                    this.$router.push("/home");
                    this.$router.go();
                    this.$loading(false);
                })
                .catch((error) => {
                    if (error.response.status == 400) {
                        this.$swal.fire({
                            position: "center",
                            icon: "warning",
                            title: 'Invalid Credentials',
                            text: "Please check your username & password",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        this.$swal.fire({
                            position: "center",
                            icon: "Error",
                            title:"Error",
                            text: error.response.message,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }

                    this.$loading(false);
                });
        },
    },
    components: {
        sidenav,
    },
};
</script>

<style scoped>
body {
    font-family: "Lato", sans-serif;
}

p {
    font-size: 72;
}

.main-head {
    height: 150px;
    background: #fff;
}

/* .main {
  padding: 0px 10px;
} */

@media screen and (max-width: 450px) {
    .login-form {
        margin-top: 10%;
    }

    .register-form {
        margin-top: 10%;
    }
}

@media screen and (min-width: 768px) {
    .main {
        margin-left: 40%;
    }

    .login-form {
        margin-top: 50%;
    }

    .register-form {
        margin-top: 20%;
    }
}

.btn-black {
    background-color: #000 !important;
    color: #fff;
}

.login-main-text {
    margin-top: 10%;
    padding: 60px;
    color: #fff;
}

.login-main-text h2 {
    font-weight: 300;
}
</style>
