<template>
<div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <h1 class="m-0 text-dark">SMS Service</h1>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-12">
                    <!-- Default box -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Enter SMS Inforamtion</h3>
                        </div>
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" value="All" name="radio1" v-model="smsType" v-on:change="cleargrid" />
                                                    <label class="form-check-label">All</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" value="Individual" name="radio1" v-model="smsType" v-on:change="cleargrid" />
                                                    <label class="form-check-label">Individual</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" value="CG" name="radio1" v-model="smsType" v-on:change="cleargrid" checked="disabled = !disabled" />
                                                    <label class="form-check-label">Customer Group</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <!-- <label for="inputName">Product Name</label> -->

                                            Select Customer Group
                                            <select class="custom-select" v-model="CustomerGroup" @change="onChange($event)" required>
                                                <option v-for="optionCGs in optionCG" :key="optionCGs.id" :value="optionCGs.id">
                                                    {{ optionCGs.options }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Customer Code
                                                    <input type="text" id="inputCusCode" class="form-control form-control-sm" v-model="CustCode" readonly />
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    Customer Name

                                                    <v-select :items="customers" :options="customers" v-model="customer" @input="getCustomer($event)" @search="searchCustomer" label="Name" item-text="Name" bottom autocomplete>
                                                    </v-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    Mobile No
                                                    <input type="text" id="inputCardNo" class="form-control form-control-sm" v-model="Mobile" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <br>
                                                    <input type="button" value=" Add to List" class="btn btn-primary" v-on:click="addtable()" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row"></div>
                                <div class="row"></div>

                                <div class="row">
                                    <div class="col-sm-12">
                                        <table id="example1" class="
                          table table-bordered table-striped
                          dataTable
                          dtr-inline
                        " role="grid" aria-describedby="example1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1" colspan="1" aria-label="Rendering engine: activate to sort column ascending">
                                                        Customer Code
                                                    </th>
                                                    <th class="sorting sorting_desc" tabindex="0" aria-controls="example1" rowspan="1" colspan="1" aria-label="Browser: activate to sort column ascending" aria-sort="descending">
                                                        Customer Name
                                                    </th>
                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1" colspan="1" aria-label="Platform(s): activate to sort column ascending">
                                                        Mobile No
                                                    </th>

                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1" colspan="1" aria-label="CSS grade: activate to sort column ascending">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(c, index) in  this.customerList" :key="index">
                                                <tr class="odd">
                                                    <td class="dtr-control">{{ c.Code }}</td>
                                                    <td class="sorting_1">{{ c.FName }}</td>
                                                    <td>{{ c.Mobile }}</td>
                                                    <td>
                                                        <input type="button" value="Remove" class="btn btn-danger" id="customerList.index" v-on:click="removetable(index)" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot></tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                            <textarea class="form-control form-control-sm" rows="5" v-model="msg"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-success float-right mr-2" v-on:click="sendAPIdata">
                                            Send Message
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <input type="button" value="Clear" class="btn btn-primary float-right mr-2" />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- /.card-body -->
                        <div class="card-footer"></div>
                        <!-- /.card-footer-->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            smsType: "All",
            msg: "",
            CustCode: "",
            CustName: "",
            Mobile: "",
            customer: null,
            customers: [],
            customerList: [],
            CustomerGroup: "",
            MobileNos: [],
            btid: "",
            disbaled: false,
        };
    },
    computed: {
        ...mapGetters(["optionCG"]),
    },

    methods: {
        ...mapActions(["getCusGroup", "sendSMSCall", "getFilterGroupCus"]),
        searchCustomer(customer) {
            this.$store.commit("searchCustomer", customer);
            this.customers = this.$store.state.customer.customers;
        },
        metOne() {
            this.getCusGroup()
                .then((res) => {})
                .catch((error) => {
                    console.log(error);
                });
        },

        onChange(event) {
            this.customerList = [];
            this.$store.dispatch("getFilterGroupCus", event.target.value).then(() => {
                this.customerList = this.$store.state.smsStore.customers;
            });
        },
        cleargrid() {
            if (this.smsType != "CG") {
                this.$store.state.customer.optionCG = null;
            } else {
                this.metOne();
            }
        },
        getCustomer(event) {
            this.CustCode = this.customer.Code;
            this.Mobile = this.customer.Mobile;
        },

        addtable() {
            if (this.smsType == "Individual") {
                var arr = this.customerList;

                //check this if exist
                const found = arr.some((el) => el.Code === this.CustCode);

                if (!found) {
                    this.customerList.push({
                        Code: this.CustCode,
                        FName: this.customer.Name,
                        Mobile: this.Mobile,
                    });
                    this.$store.state.smsStore.customers = this.customerList;
                    this.CustCode = null;
                    this.customer.Name = null;
                    this.Mobile = null;
                }
            }
        },
        removetable(index) {
            this.customerList.splice(index, 1);
        },
        sendAPIdata() {
            this.$loading(true);
            var ttt = {
                SmsType: this.smsType,
                message: this.msg,
                sendUser: localStorage.getItem("user"),
                CusGroup: this.CustomerGroup,
                mobile: this.customerList,
            };
            JSON.stringify(ttt);

            this.sendSMSCall(ttt)
                .then((res) => {
                    this.$loading(false);
                    this.$alert("Sms Sent!", "Send Messages", "success");

                    this.$router.go();
                })
                .catch((error) => {
                    this.$loading(false);
                    this.$alert(
                        "Error on Sending Sms ! (Error :" + error.response.status + ")",
                        "Send Messages",
                        "info"
                    );
                    console.log(error);
                });
        },
    },
    created() {
        this.metOne();
    },
    mounted() {

        if (
            localStorage.getItem("login") == "false" ||
            localStorage.getItem("token") == null
        ) {
            this.$router.push("/");
        }
    },
};
</script>

<style>
</style>
