<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">Customer Balance Report</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-12">
            <!-- Default box -->
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Filter Data</h3>
              </div>
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        Location
                        <select class="custom-select" v-model="loca" required>
                          <option value="0">SELECT ALL</option>
                          <option
                            v-for="l in locations"
                            :key="l.LocaCode"
                            :value="l.LocaCode"
                          >
                            {{ l.LocaName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        Customer Group
                        <select
                          class="custom-select"
                          v-model="custGroup"
                          required
                        >
                          <option value="0">SELECT ALL</option>
                          <option
                            v-for="c in customerGroups"
                            :key="c.id"
                            :value="c.id"
                          >
                            {{ c.options }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-8 bg-light text-right">
                      <br />
                      <input
                        @click="getCustomerDetails"
                        type="button "
                        class="btn-sm btn btn-success mr-1"
                        value="Process"
                        style="width: 70px"
                      />
                      <input
                        type="button"
                        class="btn btn-sm btn-warning mr-1"
                        value="View"
                        style="width: 70px"
                      />
                    </div>
                  </div>
                  <div class="row"></div>
                </form>
              </div>
            </div>

            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <table
                      class="table table-hover table-bordered"
                      id="custData"
                    >
                      <thead>
                        <tr>
                          <th>Customer Code</th>
                          <th>Card No</th>
                          <th>Customer Name</th>
                          <th class="text-right">Balance Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="c in this.customerDetials" :key="c.Code">
                          <td>{{ c.Code }}</td>
                          <td>{{ c.CardNo }}</td>
                          <td>{{ c.Name }}</td>
                          <td class="text-right">{{ c.PointBalance }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  data() {
    return {
      customerGroups: [],
      locations: [],
      reportFilter: null,
      custGroup: 0,
      loca: 0,
      customerDetials: [],
    };
  },
  computed: {
    ...mapGetters(["optionCG"]),
  },

  methods: {
    getCustomerDetails() {
      this.$loading(true);
      this.reportFilter = {
        customerGroup: this.custGroup,
        location: this.loca,
      };
      this.$store
        .dispatch("fetchCustomerDetails", this.reportFilter)
        .then(() => {
          this.customerDetials = this.$store.state.rptCustomerDetails.customerDetails;

          setTimeout(() => $("#custData").DataTable(), 1000);
        })
        .catch((error) => {});
      this.$loading(false);
    },
    cleargrid() {
      if (this.smsType != "CG") {
        this.$store.state.customer.optionCG = null;
      } else {
        this.metOne();
      }
    },
  },
  created() {
    this.$store.dispatch("fetchCustomerGroups").then(() => {
      this.customerGroups = this.$store.state.rptCustomerDetails.customerGroups;
    });
    this.$store.dispatch("fetchLocation").then(() => {
      this.locations = this.$store.state.rptCustomerDetails.locations;
    });
  },

  mounted() {},
};
</script>

<style></style>
